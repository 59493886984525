define("shared/components/accordion-list-item/component", ["exports", "shared/components/accordion-list-item/template", "jquery"], function (_exports, _template, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.classForStatus = classForStatus;
  _exports.default = _exports.STATUS_INTL_KEY = _exports.STATUS = void 0;
  var NONE = 'none';
  var INCOMPLETE = 'incomplete';
  var ERROR = 'error';
  var NOTCONFIGURED = 'notConfigured';
  var CONFIGURED = 'configured';
  var COUNTCONFIGURED = 'countConfigured';
  var STANDARD = 'standard';
  var SPECIFIC = 'specific';
  var CUSTOM = 'custom';
  var RULE = 'rule';
  var ANY = 'any';
  var STATUS = {
    NONE: NONE,
    INCOMPLETE: INCOMPLETE,
    ERROR: ERROR,
    NOTCONFIGURED: NOTCONFIGURED,
    CONFIGURED: CONFIGURED,
    COUNTCONFIGURED: COUNTCONFIGURED,
    STANDARD: STANDARD,
    CUSTOM: CUSTOM,
    SPECIFIC: SPECIFIC,
    RULE: RULE,
    ANY: ANY
  };
  _exports.STATUS = STATUS;
  var STATUS_INTL_KEY = 'accordionRow.status';
  _exports.STATUS_INTL_KEY = STATUS_INTL_KEY;

  function classForStatus(status) {
    switch (status) {
      case NONE:
      case NOTCONFIGURED:
      case STANDARD:
      case ANY:
        return 'text-muted';

      case INCOMPLETE:
      case ERROR:
        return 'text-error';

      default:
        return 'text-success';
    }
  }

  var _default = Ember.Component.extend({
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['accordion'],
    name: null,
    title: null,
    detail: null,
    status: null,
    statusClass: null,
    showStatue: true,
    intent: null,
    showExpand: true,
    expandOnInit: false,
    expanded: false,
    expandAll: false,
    everExpanded: false,
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('render', this, this.setupExpandOnInit);
    },
    actions: {
      doExpand: function doExpand() {
        if (Ember.get(this, 'showExpand')) {
          this.expand(this);
        }
      },
      goToGrafana: function goToGrafana() {
        window.open(Ember.get(this, 'grafanaUrl'), '_blank');
      }
    },
    expdObserver: Ember.on('init', Ember.observer('expanded', function () {
      var _this = this;

      if (Ember.get(this, 'expanded') && !Ember.get(this, 'intent')) {
        if (!Ember.get(this, 'everExpanded')) {
          Ember.run.next(function () {
            Ember.set(_this, 'everExpanded', true);
          });
        }

        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          Ember.set(_this, 'intent', Ember.get(_this, 'componentName'));
        });
      }
    })),
    expandAllObserver: Ember.on('init', Ember.observer('expandAll', function () {
      var _this2 = this;

      var ea = Ember.get(this, 'expandAll');

      if (ea) {
        Ember.run.next(function () {
          Ember.set(_this2, 'expanded', true);
        });
      } else {
        Ember.run.next(function () {
          Ember.set(_this2, 'expanded', false);
        });
      }
    })),
    expand: function expand() {},
    setupExpandOnInit: function setupExpandOnInit() {
      var _this3 = this;

      var eoi = Ember.get(this, 'expandOnInit');

      if (eoi) {
        if (!Ember.get(this, 'everExpanded')) {
          Ember.run.next(function () {
            Ember.set(_this3, 'everExpanded', true);
          });
        }

        Ember.run.next(function () {
          if (_this3.isDestroyed || _this3.isDestroying) {
            return;
          }

          Ember.run.next(function () {
            Ember.set(_this3, 'expanded', eoi);
          });
        });
      }

      if ((0, _jquery.default)('.accordion-detail-text a').length) {
        (0, _jquery.default)('.accordion-detail-text a').attr('onclick', 'event.stopPropagation();');
      }
    }
  });

  _exports.default = _default;
});