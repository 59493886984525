define("shared/components/node-group-row/component", ["exports", "shared/components/node-group-row/template", "shared/utils/amazon", "semver"], function (_exports, _template, _amazon, _semver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _template.default,
    classNames: ['row', 'mb-20'],
    instanceTypes: _amazon.INSTANCE_TYPES,
    clusterConfig: null,
    keyPairs: null,
    mode: null,
    model: null,
    nodeGroupsVersionCollection: null,
    originalCluster: null,
    versions: null,
    nameIsEditable: true,
    showNodeUpgradePreventionReason: false,
    upgradeVersion: false,
    creating: Ember.computed.equal('mode', 'new'),
    editing: Ember.computed.equal('mode', 'edit'),
    init: function init() {
      this._super.apply(this, arguments);

      if (this.editing) {
        if (!Ember.isEmpty(this.model.nodegroupName)) {
          Ember.set(this, 'nameIsEditable', false);
        }
      }
    },
    actions: {
      setTags: function setTags(section) {
        Ember.set(this, 'model.tags', section);
      },
      setLabels: function setLabels(section) {
        Ember.set(this, 'model.labels', section);
      }
    },
    originalClusterVersion: Ember.computed('originalCluster.eksConfig.kubernetesVersion', 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion', function () {
      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.eksConfig.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.eksConfig.kubernetesVersion');
      }

      if (!Ember.isEmpty(Ember.get(this, 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion'))) {
        return Ember.get(this, 'originalCluster.eksStatus.upstreamSpec.kubernetesVersion');
      }

      return '';
    }),
    upgradeAvailable: Ember.computed('clusterConfig.kubernetesVersion', 'mode', 'model.version', 'originalClusterVersion', 'showNodeUpgradePreventionReason', function () {
      var originalClusterVersion = Ember.get(this, 'originalClusterVersion');
      var clusterVersion = Ember.get(this, 'clusterConfig.kubernetesVersion');
      var nodeVersion = Ember.get(this, 'model.version');
      var mode = Ember.get(this, 'mode');
      var initalClusterMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(clusterVersion)), 10);
      var initalNodeMinorVersion = parseInt((0, _semver.minor)((0, _semver.coerce)(nodeVersion)), 10);
      var diff = initalClusterMinorVersion - initalNodeMinorVersion;

      if (mode === 'edit') {
        // we must upgrade the cluster first
        if (originalClusterVersion !== clusterVersion) {
          Ember.set(this, 'showNodeUpgradePreventionReason', true);
          return false;
        }
      }

      if (diff === 0 && Ember.get(this, 'showNodeUpgradePreventionReason')) {
        Ember.set(this, 'showNodeUpgradePreventionReason', false);
      }

      return diff === 1;
    }),
    clusterVersionDidChange: Ember.on('init', Ember.observer('clusterConfig.kubernetesVersion', function () {
      var clusterConfig = this.clusterConfig,
          editing = this.editing;

      if (Ember.get(clusterConfig, 'kubernetesVersion') && !editing) {
        Ember.set(this, 'model.version', clusterConfig.kubernetesVersion);
      }
    })),
    shouldUpgradeVersion: Ember.on('init', Ember.observer('upgradeVersion', function () {
      var upgradeVersion = this.upgradeVersion;
      var clusterVersion = Ember.get(this, 'clusterConfig.kubernetesVersion');
      var nodeVersion = Ember.get(this, 'model.version');

      if (upgradeVersion && clusterVersion !== nodeVersion) {
        Ember.set(this, 'model.version', clusterVersion);
      }
    })),
    removeNodeGroup: function removeNodeGroup() {
      throw new Error('remove node group action is required!');
    }
  });

  _exports.default = _default;
});