define("shared/catalog/service", ["exports", "shared/utils/util", "ui/utils/parse-externalid", "shared/utils/constants"], function (_exports, _util, _parseExternalid, _constants) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Service.extend({
    globalStore: Ember.inject.service(),
    settings: Ember.inject.service(),
    store: Ember.inject.service(),
    scope: Ember.inject.service(),
    app: Ember.inject.service(),
    catalogs: null,
    _allCatalogs: Ember.computed.union('globalCatalogs', 'clusterCatalogs', 'projectCatalogs'),
    _allTemplates: null,
    globalCatalogs: null,
    clusterCatalogs: null,
    projectCatalogs: null,
    init: function init() {
      this._super.apply(this, arguments);

      var store = Ember.get(this, 'globalStore');
      Ember.setProperties(this, {
        globalCatalogs: store.all('catalog'),
        clusterCatalogs: store.all('clustercatalog'),
        projectCatalogs: store.all('projectcatalog'),
        '_allTemplates': store.all('template')
      });
    },
    reset: function reset() {
      this.setProperties({
        catalogs: null
      });
    },
    refresh: function refresh() {
      var _this = this;

      var store = Ember.get(this, 'globalStore');
      return this.fetchUnScopedCatalogs().then(function () {
        return Ember.RSVP.hash({
          projectCatalogs: store.request({
            method: 'POST',
            url: "".concat(Ember.get(_this, 'app.apiEndpoint'), "/projectcatalogs?action=refresh")
          }),
          clusterCatalogs: store.request({
            method: 'POST',
            url: "".concat(Ember.get(_this, 'app.apiEndpoint'), "/clustercatalogs?action=refresh")
          }),
          globalCatalogs: store.request({
            method: 'POST',
            url: "".concat(Ember.get(_this, 'app.apiEndpoint'), "/catalogs?action=refresh")
          })
        });
      });
    },
    fetchAppTemplates: function fetchAppTemplates(apps) {
      var _this2 = this;

      var deps = [];
      apps.forEach(function (app) {
        var extInfo = (0, _parseExternalid.parseHelmExternalId)(app.get('externalId'));

        if (extInfo && extInfo.templateId) {
          deps.push(_this2.fetchTemplate(extInfo.templateId, false));
        }
      });
      return Ember.RSVP.allSettled(deps);
    },
    fetchMultiClusterAppTemplates: function fetchMultiClusterAppTemplates(apps) {
      var _this3 = this;

      var deps = [];
      apps.forEach(function (app) {
        var extInfo = Ember.get(app, 'externalIdInfo');

        if (extInfo && extInfo.templateId) {
          deps.push(_this3.fetchTemplate(extInfo.templateId, false));
        }
      });
      return Ember.RSVP.allSettled(deps);
    },
    fetchUnScopedCatalogs: function fetchUnScopedCatalogs() {
      return Ember.RSVP.hash({
        projectCatalogs: this.fetchCatalogs('projectCatalog'),
        clusterCatalogs: this.fetchCatalogs('clusterCatalog'),
        globalCatalogs: this.fetchCatalogs('catalog')
      });
    },
    fetchCatalogs: function fetchCatalogs() {
      var catalogs = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'catalog';
      var opts = arguments.length > 1 ? arguments[1] : undefined;
      return Ember.get(this, 'globalStore').findAll(catalogs, opts);
    },
    getTemplateFromCache: function getTemplateFromCache(id) {
      return Ember.get(this, 'globalStore').getById('template', id);
    },
    getVersionFromCache: function getVersionFromCache(id) {
      return Ember.get(this, 'globalStore').getById('templateversion', id);
    },
    fetchTemplate: function fetchTemplate(id) {
      var upgrade = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var type, cached;

      if (upgrade === true) {
        type = 'templateversions';
        cached = this.getVersionFromCache(id);
      } else {
        type = 'templates';
        cached = this.getTemplateFromCache(id);
      }

      if (cached) {
        return Ember.RSVP.resolve(cached);
      }

      var url = this._addLimits("".concat(Ember.get(this, 'app.apiEndpoint'), "/").concat(type, "/").concat(id));

      return Ember.get(this, 'globalStore').request({
        url: url
      });
    },
    fetchTemplates: function fetchTemplates() {
      var _this4 = this;

      if (arguments.length) {
        console.error('Deprecated', new Error("Use a catalogService.filter(globalStore.all('templates'))"));
      }

      var globalStore = Ember.get(this, 'globalStore');
      var qp = {
        'category_ne': 'system'
      };

      var url = this._addLimits("".concat(Ember.get(this, 'app.apiEndpoint'), "/templates"), qp);

      return globalStore.request({
        url: url
      }).then(function () {
        return {
          catalog: _this4._allTemplates
        };
      });
    },
    cleanVersionsArray: function cleanVersionsArray(template) {
      return Object.keys(template.versionLinks).filter(function (key) {
        // Filter out empty values for rancher/rancher#5494
        return !!template.versionLinks[key];
      }).map(function (key) {
        return {
          version: key,
          sortVersion: key,
          link: template.versionLinks[key]
        };
      });
    },
    fetchByUrl: function fetchByUrl(url) {
      return Ember.get(this, 'store').request({
        url: url
      });
    },
    filter: function filter(data, project, istio) {
      var currentProjectId = project ? project.id : null;
      var currentClusterId = project ? project.clusterId : null;
      data = data.filter(function (tpl) {
        if (tpl.clusterId && tpl.clusterId !== currentClusterId) {
          return false;
        }

        if (tpl.projectId && tpl.projectId !== currentProjectId) {
          return false;
        }

        if (_typeof(istio) !== undefined) {
          if (istio !== Ember.get(tpl, 'isIstio')) {
            return false;
          }

          if (!istio && Ember.get(tpl, 'catalogId') === _constants.default.CATALOG.SYSTEM_LIBRARY_KEY) {
            return false;
          }
        }

        return true;
      });
      data = data.sortBy('name');
      return data;
    },
    uniqueCategories: function uniqueCategories(data) {
      var out = [];
      data.forEach(function (obj) {
        out.pushObjects(obj.get('categoryArray'));
      });
      out = (0, _util.uniqKeys)(out);
      out.unshift('');
      return out;
    },
    _addLimits: function _addLimits(url, qp) {
      qp = qp || {};
      url = (0, _util.addQueryParams)(url, qp);
      return url;
    }
  });

  _exports.default = _default;
});